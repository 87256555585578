<!-- 仪器连接 -->
<template>
  <div class="instrument-connection">
    <div class="connection-content">
      <el-card class="connection-card">
        <div class="card-title">WiFi 连接配置</div>
        <div class="qrcode-container" v-if="qrCodeData">
          <img :src="qrCodeData" alt="WiFi二维码" class="qrcode-image">
          <div class="qrcode-tip">扫描二维码连接WiFi</div>
        </div>
        <el-form
          ref="dataForm"
          :rules="dataRule"
          :model="dataForm"
          label-width="100px"
          class="connection-form"
        >
          <el-form-item
            label="WiFi名称"
            prop="ssid"
          >
            <el-input
              v-model="dataForm.ssid"
              placeholder="请输入WiFi名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
          >
            <el-input
              v-model="dataForm.password"
              placeholder="请输入WiFi密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          class="dialog-footer"
        >
          <el-button
            type="primary"
            @click="dataFormSubmit()"
          >确定</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { showLoading, hideLoading } from '@/utils/loading'

export default {
  data() {
    return {
      loading: false,
      qrCodeData: '', // 添加二维码数据
      dataForm: {
        ssid: "",
        password: "",
      },
      dataRule: {
        ssid: [
          { required: true, message: "请输入WiFi名称", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入WiFi密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          showLoading();  // 显示loading
          let data = this.dataForm;  
          console.log('data', data)
          this.$http.get(`tools/generateQRCode`, {params: data}).then((res) => {
            if (res.data.code == 200) {
              this.qrCodeData = res.data.data;
              console.log('res', res.data.data)
              this.$message({
                message: "操作成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          }).finally(() => {
            hideLoading();  // 关闭loading
          });;
        }
      });
    },
  },
};
</script>

<style scoped>
.instrument-connection {
  padding: 40rpx;
}

.connection-content {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .connection-content {
    margin: 50px 10px 0;
  }
}

.connection-form {
  padding: 40rpx;
  max-width: 600px;
  margin: 0 auto;
}

.connection-card {
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #303133;
  margin-bottom: 25px;
  text-align: center;
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-input__inner) {
  border-radius: 4px;
}

.dialog-footer {
  text-align: center;
  margin-top: 30px;
}

.dialog-footer .el-button {
  min-width: 120px;
}
.qrcode-container {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
}

.qrcode-image {
  max-width: 200px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.qrcode-tip {
  color: #606266;
  font-size: 14px;
}
</style>